<template>
  <div class="chevron">
    <span> New </span>
    <img src="../assets/chevron.svg" />
  </div>
</template>

<script setup lang="ts"></script>
<style scoped>
.chevron {
  position: relative;
  display: flex;

  span {
    position: absolute;
    top: 4px;
    left: 5px;
    transform: rotate(-6deg);

    /* stylelint-disable */
    font-family: 'SF Pro Display';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    /* stylelint-enable */
  }
}
</style>
