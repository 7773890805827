<template>
  <div
    class="buy-crypto-tooltip"
    :class="wrapperClasses"
    data-t="buy-crypto-tooltip"
  >
    <StTooltip v-if="platform === 'desktop'">
      <template #activator>
        <div class="label">
          {{ t('payments.buyCrypto.tooltipHeader') }}
          <StIcon name="circle-info-solid" size="12" />
        </div>
      </template>
      <div class="content">
        <p>
          {{ t('payments.buyCrypto.tooltipContentText') }}
        </p>
        <p v-if="!isHomePage">
          {{ t('payments.buyCrypto.tooltipContentText2') }}
        </p>
        <p v-if="!isHomePage">
          {{ t('payments.buyCrypto.tooltipContentText3') }}
        </p>
      </div>
    </StTooltip>
    <div v-else>
      <div class="label" @click="isDrawerOpened = true">
        {{ t('payments.buyCrypto.tooltipHeader') }}
        <StIcon name="circle-info-solid" size="12" />
      </div>
      <StDrawer v-model="isDrawerOpened" :full-screen="false">
        <div class="mobile-title">
          {{ t('payments.buyCrypto.tooltipHeader') }}
          <StIcon
            name="cross-large"
            size="20"
            @click="isDrawerOpened = false"
          />
        </div>
        <div class="mobile-content">
          <p>
            {{ t('payments.buyCrypto.tooltipContentText') }}
          </p>
          <p v-if="!isHomePage">
            {{ t('payments.buyCrypto.tooltipContentText2') }}
          </p>
          <p v-if="!isHomePage">
            {{ t('payments.buyCrypto.tooltipContentText3') }}
          </p>
        </div>
      </StDrawer>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  isHomePage?: boolean
}>()

const { t } = useI18n()
const { platform } = usePlatform()
const isDrawerOpened = ref(false)

const wrapperClasses = computed(() => ({
  mobile: platform.value === 'mobile',
  'home-page': props.isHomePage,
}))
</script>

<style scoped>
.buy-crypto-tooltip {
  cursor: pointer;
  display: flex;
}

.label {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  font: var(--desktop-text-xxs-medium);
  color: var(--text-secondary);
}

.content {
  width: 360px;
  padding: var(--spacing-200);
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.home-page {
  .content {
    width: 417px;
  }

  .label {
    font: var(--mobile-caption-2-medium);
  }
}

.mobile-title {
  display: flex;
  gap: var(--spacing-150);
  padding: var(--spacing-100) var(--spacing-200) 0;
  font: var(--mobile-title-2-semibold);
}

.mobile-content {
  padding: 0 var(--spacing-200) var(--spacing-200);
  font: var(--mobile-caption-1-medium);
  color: var(--text-tertiary);
}
</style>
